import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
* {
  font-family: 'Roboto', sans-serif;
}
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  .dark-light{
    display:none;
  }
  .shade {
    position: absolute;
    left: auto;
    top: auto;
    right: 10%;
    bottom: 10%;
    transform: rotate(45deg);
    z-index:  -1;
    width:  300px;
    height: 200px;
    border-radius:  1000px;
    background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%);
    opacity: 0.55;
    // -webkit-filter: blur(100px);
    filter: blur(100px);
}
.shade2 {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index:  -1;
    width:  300px;
    height: 300px;
    border-radius:  1000px;
    background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(18 52 140 / 58%) 0%, rgb(18 52 140 / 55%) 95.78%);
    opacity: 0.55;
    // -webkit-filter: blur(100px);
    filter: blur(100px);
}
.shade3 {
    position: absolute;
    left: 10%;
    top: 0%;
    right: auto;
    bottom: auto;
    transform: rotate(45deg);
    z-index:  -1;
    width:  250px;
    height: 250px;
    border-radius:  1000px;
    background-image: radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%);
    opacity: 0.55;
    // -webkit-filter: blur(100px);
    filter: blur(100px);
}
`

export default GlobalStyle
