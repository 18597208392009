import React, { useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light, dark } from '@pancakeswap-libs/uikit'

const CACHE_KEY = 'IS_DARK'

const ThemeContext = React.createContext({ isDark: null, toggleTheme: () => null })

const ThemeContextProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : true
  })

  const toggleTheme = () => {
    setIsDark((prevState) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }
  const lightTheme = {
    ...light,
    button: {
      ...light.button,
      primary: {
        ...light.button.primary,
        background: '#282828',
        backgroundActive: '#282828',
        backgroundHover: '#282828',
      },
      text: {
        ...light.button.text,
        color: '#282828',
      },
      tertiary: {
        ...light.button.tertiary,
        color: '#282828',
      },
      secondary: {
        ...light.button.secondary,
        color: '#282828',
      },
      subtle: {
        ...light.button.subtle,
        background: '#282828',
        backgroundActive: '#282828',
        backgroundHover: '#282828',
      },
    },
    colors: {
      ...light.colors,
      primary: '#282828',
      primaryBright: '#b48849',
      primaryDark: '#b48849',
      textSubtle: '#282828',
      secondary: '#b48849',
      text: '#282828',
    },
  }
  const darkTheme = {
    ...dark,
    alert: { background: '#272c2c' },
    card: {
      ...dark.card,
      background: '#272c2c',
    },
    modal: { background: '#272c2c' },
    nav: { background: '#272c2c', hover: '#012b2c' },
    radio: { handleBackground: '#272c2c' },
    toggle: { handleBackground: '#272c2c' },
    button: {
      ...dark.button,
      primary: {
        ...dark.button.primary,
        background: '#2fb4f1',
        backgroundActive: '#1782b3',
        backgroundHover: '#1782b3',
      },
      text: {
        ...dark.button.text,
        color: '#2fb4f1',
        backgroundHover: '#1782b3',
      },
      tertiary: {
        ...dark.button.tertiary,
        color: '#ffffff',
        background: '#1782b3',
        backgroundActive: '#1782b3',
        backgroundHover: '#1782b3',
      },
      secondary: {
        ...dark.button.secondary,
        color: '#2fb4f1',
        border: '2px solid #2fb4f1',
        borderColorHover: '#2fb4f1',
      },
      subtle: {
        ...dark.button.subtle,
        background: '#2fb4f1',
        backgroundActive: '#2fb4f1',
        backgroundHover: '#2fb4f1',
      },
    },
    colors: {
      ...dark.colors,
      primary: '#ffffff',
      primaryBright: '#42E8E0',
      primaryDark: '#ffffff',
      // textSubtle: '#282828',
      secondary: '#1782b3',
      // text: '#282828',
      background: '#000000',
      card: '#272c2c',
      tertiary: '#1782b3',
      input: '#494e4e',
    },
  }
  console.log({ lightTheme, darkTheme })
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      {/* <SCThemeProvider theme={lightTheme}>{children}</SCThemeProvider> */}
      <SCThemeProvider theme={isDark ? darkTheme : lightTheme}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
