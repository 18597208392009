import { MenuEntry } from '@pancakeswap-libs/uikit'
import { BsTelegram, BsTwitter, BsFacebook, BsYoutube, BsReddit, BsInstagram } from 'react-icons/bs'
import { FaTelegramPlane } from 'react-icons/fa'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://app.pangolin.exchange/#/swap?exactField=input&exactAmount=1000&outputCurrency=0xBf6daDb7acb53280b813A9a58add5d8e3eAa67E7',
      },
      {
        label: 'Liquidity',
        href: 'https://app.pangolin.exchange/#/add/AVAX/0xBf6daDb7acb53280b813A9a58add5d8e3eAa67E7',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ],
  // },
  // {
  //   label: 'Socials',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Telegram',
  //       href: '/',
  //     },
  //     {
  //       label: 'Twitter',
  //       href: '/',
  //     },
  //     {
  //       label: 'Facebook',
  //       href: '/',
  //     },
  //     {
  //       label: 'Github',
  //       href: '/',
  //     },
  //     {
  //       label: 'Instagram',
  //       href: '/',
  //     },
  //     {
  //       label: 'Reddit',
  //       href: '/',
  //     },
  //     {
  //       label: 'Medium',
  //       href: '/',
  //     },
  //     {
  //       label: 'Discord',
  //       href: '/',
  //     },
  //   ],
  // },
]

export default config

export const socialsLinks = [
  {
    label: 'Telegram',
    icon: FaTelegramPlane,
    href: 'https://t.me/DexDaddyOfficialGroup',
  },
  {
    label: 'Telegram',
    icon: BsTelegram,
    href: 'https://t.me/DexDaddyOfficialChannel',
  },
  
  {
    label: 'Twitter',
    icon: BsTwitter,
    href: 'https://twitter.com/DexDaddyDeFi',
  },
  {
    label: 'Facebook',
    icon: BsFacebook,
    href: 'https://www.facebook.com/DexDaddyOfficial',
  },
  {
    label: 'Instagram',
    icon: BsInstagram,
    href: 'https://www.instagram.com/dexdaddyofficial',
  },
  {
    label: 'Youtube',
    icon: BsYoutube,
    href: 'https://www.youtube.com/channel/UCOtk9Ke9QYZn6tw9gVF3eVQ',
  },
]