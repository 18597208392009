export default {
  cake: {
    56: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    97: '',
    43113: '0xBf6daDb7acb53280b813A9a58add5d8e3eAa67E7',
  },
  masterChef: {
    56: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
    97: '',
    43113: '0xA1BEC19C4151785E51Af6AD4E0E25f91A1636E64',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
    43113: '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
  },
  lottery: {
    56: '',
    97: '',
    43113: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
    43113: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
    43113: '0xb465Fd2d9C71d5D6e6c069aaC9b4E21c69aAA78f',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
    43113: '0x2d6b3691602f6a8f46D32d29260511f6a7f2869d',
  },
}
